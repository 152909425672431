import React from "react";
import Footer from "src/components/Footer";

class Page extends React.Component {
  render() {
    return (
      <div>
        <header className="header">
          <div className="inner">
            <div className="row header-top">
              <div className="four offset-by-six columns">
                <nav>
                  <ol className="uilist-hor nav-top">
                    <li className="first">
                      <a href="/fr/contact/">Contact</a>
                    </li>
                    <li>
                      <a href="/fr/lexique/">Lexique</a>
                    </li>
                    <li className="last">
                      <a href="/fr/faq-questions-diarrhee/">FAQ</a>
                    </li>
                  </ol>
                  <ul className="uilist-hor nav-top nav-lang">
                    <li id="uilist-hor nav-top nav-lang_nl-BE">
                      <a href="/cookiebeleid/">NL</a>
                    </li>
                    <li id="uilist-hor nav-top nav-lang_fr" className="active">
                      <a href="/fr/politique-en-matiere-de-cookies/">FR</a>
                    </li>
                  </ul>
                </nav>
              </div>
              <div className="two columns">
                <form
                  className="search"
                  method="post"
                  action="/"
                >
                  <div className="hiddenFields">
                    <input
                      type="hidden"
                      name="XID"
                      defaultValue="f516bcd4282a0d4fb53123a36b9562b62a10b23d"
                    />
                    <input type="hidden" name="ACT" defaultValue="45" />
                    <input
                      type="hidden"
                      name="result_page"
                      defaultValue="/fr/chercher"
                    />
                    <input
                      type="hidden"
                      name="collection"
                      defaultValue="pages"
                    />
                    <input
                      type="hidden"
                      name="search_mode"
                      defaultValue="all"
                    />
                    <input type="hidden" name="site_id" defaultValue="1" />
                  </div>
                  <div className="row">
                    <div className="twelve columns">
                      
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="row header-bottom">
              <div className="two columns">
                <span className="logo">
                  <a href="/fr/">Imodium®</a>
                </span>
              </div>
              <div className="ten columns">
                <nav className="top-bar">
                  <ul className="nav-info">
                    <li className="name">
                      <h1>
                        <a href="#">Menu</a>
                      </h1>
                    </li>
                    <li className="toggle-topbar">
                      <a href="#" />
                    </li>
                  </ul>
                  <section>
                    <ol className="uilist-hor nav-main">
                      <li className="first" id="nav-sub-4">
                        <a href="/fr/comment-agit-imodium/">
                          Comment agit
                          <br />
                          IMODIUM®?
                        </a>
                      </li>
                      <li id="nav-sub-5">
                        <a href="/fr/medicaments-imodium/">
                          Médicaments <br />
                          IMODIUM®
                        </a>
                      </li>
                      <li id="nav-sub-6">
                        <a href="/fr/la-diarrhee/">
                          Diarrhée – causes
                          <br />
                          et traitement
                        </a>
                      </li>
                      <li id="nav-sub-8">
                        <a href="/fr/diarrhee-en-voyage/">
                          Diarrhée
                          <br />
                          en voyage
                        </a>
                      </li>
                      <li id="nav-sub-9">
                        <a href="/fr/diarrhee-chez-les-enfants/">
                          Diarrhée chez
                          <br />
                          les enfants
                        </a>
                      </li>
                      <li className="last" id="nav-sub-10">
                        <a href="/fr/materiel-dinformation-diarrhee/">
                          Matériel
                          <br />
                          d'information
                        </a>
                      </li>
                    </ol>
                  </section>
                </nav>
                <script
                  type="text/javascript"
                  dangerouslySetInnerHTML={{
                    __html:
                      '\nvar item = document.getElementById("nav-sub-10").getElementsByTagName("a");\nvar span = document.createElement("span");\nspan.className = "menu-info";\nspan.innerHTML = " ";\nitem[0].appendChild(span);\n'
                  }}
                />
              </div>
            </div>
          </div>
        </header>
        <div className="main row">
          <div className="sidebar three columns">
            <ul className="side-nav">
              <li className="active first overview last">
                <a href="/fr/politique-en-matiere-de-cookies/">
                  {" "}
                  Politique en matière de cookies
                </a>
              </li>
            </ul>
          </div>
          <div className="page nine columns page-131">
            <h1>Politique en matière de cookies</h1>
            <p>
               Cette politique en matière de cookies a pour objectif de vous informer sur nos pratiques quant à la collecte d’informations vous concernant à l’aide de cookies et d’autres technologies de repérage telles que les gifs, web beacons, etc.
            </p>
            <p>
              Il est possible que nous utilisions ces technologies pour les raisons suivantes:
            </p>
            <ul>
            <li>Vous aider lors de votre navigation,</li>
            <li>
            Aider à vous inscrire à nos évènements, à vous connecter, ainsi que à fournir des commentaires
            </li>
            <li>
             Analyser l’utilisation de nos produits, services ou applications,
              </li>
             <li>
           Supporter nos efforts promotionnels et marketing (ceci inclus la publicité comportementale),
               </li>
               <li>
             Proposer du contenu de parties tierces (par exemple les médias sociaux).
               </li>
               </ul>
               <p>
             Vous-pouvez trouver ci-dessous une liste détaillée des cookies que nous utilisons ainsi qu’une description de leur utilisation. Nous les avons regroupés par catégorie comme listé ci-dessous :
               </p>
                <ul>
              <li>Cookies strictement nécessaires</li>
              <li>
               Cookies de performance
               </li>
               <li>
                Cookies de fonctionnalité
               </li>
                <li>
                  Cookies pour une publicité ciblée
               </li>
                        
                      </ul>
                      <p>
                        Sauf dans les cas où ce serait autorisé par la loi, nous 
                        utilisons les cookies seulement après avoir reçu votre 
                        consentement pour leur utilisation, soit à travers le bandeau
                         de cookie soit à travers le gestionnaire de consentement centralisé. 
                         Vous pouvez adapter votre consentement par catégorie de 
                         cookie (à l’exception des cookies strictement nécessaires qui 
                         sont utilisés pour le fonctionnement du site) à tout moment en 
                         cliquant sur le bouton “Politique en matière de cookies” ci-dessous:
                      </p>
                      <div><a href="" id="ot-sdk-btn" class="ot-sdk-show-settings">Paramètres des cookies</a></div>
                    <br />
                    <p>&nbsp;</p>
                    <div id="ot-sdk-cookie-policy"></div>
                      <h2>Liens vers d’autres sites</h2>
                      <p>
                        Ce site peut contenir des liens ou des références vers d’autres sites web. Nous voudrions attirer votre attention sur le fait que nous ne contrôlons pas les cookies ou les technologies de repérage des autres sites web et que la présente politique en matière de cookies ne s’applique pas aux dits sites web.
                      </p>
                      <h2>Comment nous contacter</h2>
                      <p>
                        Si vous avez des questions, des commentaires ou des préoccupations par rapport à la présente politique en matière de cookies, veuillez utiliser les coordonnées de contact inclues dans notre politique de confidentialité.
                      </p>
                      <h2>
                        Changements à cette politique en matière de cookies
                      </h2>
                      <p>
                        En cas de modification de la présente politique de confidentialité, la version révisée sera publiée sur ce site. La présente Politique en matière de cookies a été mise à jour pour la dernière fois le 17-05-2021.
                      </p>
                      
          </div>
        </div>
        <Footer></Footer>
        <div id="myModal" className="reveal-modal large">
          <style
            dangerouslySetInnerHTML={{
              __html:
                ".embed-container { position: relative; padding-bottom: 56.25%; height: 0; overflow: hidden; max-width: 100%; } .embed-container video, .embed-container object, .embed-container embed { position: absolute; top: 0; left: 0; width: 100%; height: 100%; }"
            }}
          />
          <div className="embed-container">
            <video
              src="/assets/video/imodium-2018-fr.mp4"
              poster="posterimage.jpg"
            />
          </div>
          <a className="close-reveal-modal">×</a>
        </div>
        <input type="hidden" name="language" defaultValue="fr" id="lg" />
        <script src="//ajax.googleapis.com/ajax/libs/jquery/3.4.1/jquery.min.js" />
        <script src="https://code.jquery.com/jquery-migrate-3.2.0.js"></script>
        <script
          dangerouslySetInnerHTML={{
            __html:
              "window.jQuery || document.write('<script src=\"/assets/js/vendor/jquery-3.4.1.min.js\"><\\/script>')"
          }}
        />
        <script src="/assets/js/vendor/jquery.flexslider-min.js" />
        <script src="/assets/js/vendor/hash.js" />
        <script src="/assets/js/foundation/jquery.foundation.navigation.js" />
        <script src="/assets/js/foundation/jquery.foundation.topbar.js" />
        <script src="/assets/js/foundation/jquery.foundation.forms.js" />
        <script src="/assets/js/foundation/jquery.foundation.reveal.js" />
        <script src="/assets/js/vendor/swfobject.js" />
        <script src="/assets/js/vendor/yepnope.1.5.4-min.js" />
        <script src="/assets/js/app.js" />
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\nfunction cookiesDirectiveScriptWrapper(){\n// declare teh used cookie-3rd-parties\n}\n"
          }}
        />
        
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\n// The position of the disclosure ('top' or 'bottom')\n// Number of times to display disclosure. Enter 0 to show it forever!!!!\n// The URI of your privacy policy\nif($(\"body\").hasClass(\"nl-BE\")){cookiesDirective('bottom',5,'/cookiebeleid');}else{cookiesDirective('bottom',5,'/fr/politique-en-matiere-de-cookies');}\n"
          }}
        />
      </div>
    );
  }
}

export default Page;
